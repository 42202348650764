@font-face {
   font-family:
      AvenirNext;
   font-display: swap;
   src:
      url("./fonts/AvenirNextCyr-Regular.woff2") format("woff2"),
      url("./fonts/AvenirNextCyr-Regular.woff") format("woff");
   font-weight:
      400;
   font-style: normal;
}

@font-face {
   font-family:
      AvenirNext;
   font-display: swap;
   src:
      url("./fonts/AvenirNextCyr-Medium.woff2") format("woff2"),
      url("./fonts/AvenirNextCyr-Medium.woff") format("woff");
   font-weight:
      500;
   font-style: normal;
}

@font-face {
   font-family:
      AvenirNext;
   font-display: swap;
   src:
      url("./fonts/AvenirNextCyr-Demi.woff2") format("woff2"),
      url("./fonts/AvenirNextCyr-Demi.woff") format("woff");
   font-weight:
      600;
   font-style: normal;
}

body {
   font-family: "AvenirNext";
}

.PhoneInput {
   gap: 10px;
   display: none;
}

.PhoneInputCountrySelect {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;
   width: 100%;
   height: 100%;
   opacity: 0;
   cursor: pointer;
}

.PhoneInputCountry {
   position: relative;
   height: 20px;
}

.PhoneInputCountryIcon {
   width: 30px;
}

.PhoneInputInput {
   color: white;
   font-size: inherit;
}